<template>
  <div
    v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'"
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Mural</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento do
                  <span id="txtDashboardNomePlataforma">mural</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1">
                  <h4 class="aluno_font_color">
                    Lista de publicações ({{ fastMural.TotalResults }})
                  </h4>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1 text-right">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showModal('modalCriarMural')"
                  >
                    <small>+ Criar publicação</small>
                  </button>
                </div>
                <div
                    v-if="fastMural.Loading"                            
                    class="col-12 mt-2 text-center"
                  >
                  <h4><b-icon
                      icon="gear-fill"
                      animation="spin"
                    /> Carregando</h4>
                  </div>
                <div
                  v-else-if="fastMural.TotalResults"
                  class="col-12 mt-2"
                >
                  <div
                    v-for="mural in fastMural.Data"
                    :key="mural.row_number"
                    class="row"
                  >
                    <div class="col-12">
                      <tabs :options="{ useUrlFragment: false }">
                        <tab
                          prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                          name="Publicação"
                        >
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6 class="aluno_font_color">
                                Título da publicação
                              </h6>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 mt-2">
                              <input
                                v-if="mural.html_publicacao && mural.id_plataforma == $route.params.id_plataforma"
                                v-model="mural.titulo_publicacao"
                                type="text"
                                class="form-control"
                                @keyup="mural.alteracao_pendente = true"
                              >
                              <input v-else type="text" class="form-control" :value="mural.titulo_publicacao" readonly/>
                            </div>
                          </div>
                          <div class="row mt-4" v-if="mural.html_publicacao && mural.id_plataforma == $route.params.id_plataforma">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6>Texto publicado</h6>
                            </div>
                            <button
                              class="btn btn-sm btn-primary ml-3 mb-1"
                              @click="exibeModalAdicionarImagem(mural)"
                            >
                              <small>Adicionar Imagem</small>
                            </button>
                            
                            <div class="col-12 table-responsive ml-2">
                              <quill-editor
                                v-model="mural.html_publicacao"
                                :options="editorOption"
                                @change="mural.alteracao_pendente = true"
                              />
                            </div>
                          </div>
                          <div class="row mt-4" v-else-if="mural.html_publicacao && mural.id_plataforma != $route.params.id_plataforma">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                              <h6>Texto publicado</h6>
                            </div>
                            
                            <div class="col-12 table-responsive ml-2" v-html="mural.html_publicacao">
                            </div>
                          </div>
                          <div class="row mt-4" v-else-if="mural.loadingMural">
                            <div class="col-12 text-center">
                              <b-icon
                              icon="gear-fill"
                              animation="spin"
                              /> Carregando
                            </div>
                          </div>
                          <div class="row mt-4 text-center" v-else>
                            <div class="col-12 text-left">
                              <button v-if="mural.id_plataforma == $route.params.id_plataforma" class="btn btn-sm btn-primary" @click="getMural(mural.id_mural)">                                
                                <b-icon icon="pencil-square"/>
                                 Editar publicação
                              </button>
                              <button v-else class="btn btn-sm btn-primary" @click="getMural(mural.id_mural)">                                
                                <b-icon icon="eye-fill"/>
                                 Ver publicação
                              </button>
                            </div>
                            
                          </div>
                          <div
                            v-if="mural.alteracao_pendente"
                            class="row mt-4"
                          >
                            <div class="col-12 text-center">
                              <button
                                class="btn btn-sm btn-primary blink_me mr-2"
                                @click="editarMural(mural)"
                              >
                                <small>Salvar alterações</small>
                              </button>
                              <button
                                class="btn btn-sm btn-secondary mr-2"
                                @click="getFastMural()"
                              >
                                <small>Cancelar</small>
                              </button>
                            </div>
                          </div>
                          <div v-if="mural.id_plataforma != $route.params.id_plataforma" class="row mt-2">
                            <div class="col-12">
                              <small class="text-danger">Essa publicação pertence a plataforma <b>{{ mural.nome_plataforma }}</b> e não pode ser editada!</small>
                            </div>
                          </div>
                        </tab>
                        <tab
                          v-if="mural.id_plataforma == $route.params.id_plataforma"
                          prefix="<i class='fa fa-info-circle' aria-hidden='true'></i>&nbsp"
                          name="Opções"
                        >
                          <button
                            class="btn btn-sm btn-danger"
                            @click="exibeModalExcluirMural(mural)"
                          >
                            <small>Excluir</small>
                          </button>
                        </tab>
                      </tabs>
                    </div>
                  </div>
                  <!-- <div class="col-12 text-center mt-4">
                    <Pagination
                      :page-size="10"
                      :items="fastMuralFiltro"
                      @changePage="pageOfMural = $event"
                    />
                  </div> -->
                </div>
                <div
                  v-else                          
                  class="col-12 mt-2 text-center"
                >
                  <h4>Nenhuma publicação no mural</h4>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarMural"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova publicação</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarMural')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label for>Título da publicação</label>
              <input
                v-model="fastMuralNovo.titulo_publicacao"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-12">
              <label for>Enviar email</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item p-0 border-0 shadow-none">
                    <label class="switch">
                      <input
                        v-model="fastMuralNovo.email_envio"
                        type="checkbox"
                        :checked="fastMuralNovo.email_envio"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              
              <label for>Texto da publicação</label>
            
              <div class="col-sm-12 col-md-12 col-lg-3">
                <!-- Upload aqui -->
                <input
                  id="image_mural"
                  type="file"
                  class="custom-file-input"
                  accept="image/*"
                  @change="setImageMural"
                >
                <label
                  class="custom-file-label text-left"
                  for="image_mural"
                ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                <div class="invalid-feedback">
                  Arquivo inválido
                </div>
                <!-- /Upload aqui -->
              </div>
              <quill-editor
                v-model="fastMuralNovo.html_publicacao"
                :options="editorOption"
              />
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarMural()"
                >
                  Criar publicação
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirMural"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir publicação?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirMural')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="excluirMural()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalAdicionarImagem"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Adicionar Imagem</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAdicionarImagem')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <!-- Upload aqui -->
                  <input
                    id="image_mural"
                    type="file"
                    class="custom-file-input"
                    accept="image/*"
                    @change="setImageMuralEdit"
                  >
                    <label
                      class="custom-file-label text-left"
                      for="image_mural"
                    ><b-icon icon="file-earmark-image"></b-icon> Inserir imagem...</label>
                    <div class="invalid-feedback">
                      Arquivo inválido
                    </div>
                    <!-- /Upload aqui -->
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "HomeInternoAdminMural",
  components: {
    Pagination,
    quillEditor,
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      // Mural
      fastMuralTotal: [],
      fastMuralFiltro: [],
      pageOfMural: [],
      fastMuralNovo: {
        data_alteracao: null,
        data_publicacao: null,
        html_publicacao: "",
        id_mural: 0,
        id_plataforma: 0,
        id_usuario_alteracao: 0,
        id_usuario_criacao: 0,
        logo_plataforma: "",
        nome_plataforma: "",
        tipo: "",
        titulo_publicacao: "",
        email_envio: false,
      },
      fastMuralEditar: {
        data_alteracao: "",
        data_publicacao: "",
        html_publicacao: "",
        id_mural: "",
        id_plataforma: 0,
        id_usuario_alteracao: "",
        id_usuario_criacao: "",
        logo_plataforma: "",
        nome_plataforma: "",
        tipo: "",
        titulo_publicacao: "",
      },
      fastMuralExcluir: {
        data_alteracao: "",
        data_publicacao: "",
        html_publicacao: "",
        id_mural: "",
        id_plataforma: 0,
        id_usuario_alteracao: "",
        id_usuario_criacao: "",
        logo_plataforma: "",
        nome_plataforma: "",
        tipo: "",
        titulo_publicacao: "",
      },
      fastMural: {
        Loading: true,
        PageIndex: 1,
        PageSize: 5,
        RangeStart: 0,
        RangeEnd: 0,
        TotalResults: 0,
        Errors: [],
        Data: []
      },
      // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }],
          ],
        },
      },
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.fastMural.PageIndex = 1;
          this.getMuralPaginado();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Mural
    // async getFastMural() {
    //   try {
    //     let resp = await fetch(
    //       settings.endApiFastEad +
    //         "api/fast_mural/lista_admin?id_plataforma=" +
    //         this.$route.params.id_plataforma +
    //         "&id_mural=0",
    //       this.fastAjaxOptions("GET")
    //     );
    //     let json = await resp.json();
    //     let obj = Array.from(json);
    //     if (obj.length) {
    //       this.fastMuralTotal = obj;
    //       this.fastMuralFiltro = obj;
    //     } else {
    //       this.fastMuralTotal = [];
    //       this.fastMuralFiltro = [];
    //     }
    //     this.$store.state.fastCarregando = false;
    //   } catch (e) {
    //     console.log("Erro", e);
    //   }
    // },
    async getMural(id_mural) {
      this.fastMural.Data = this.fastMural.Data.map(m => {
        m.id_mural == id_mural ? m.loadingMural = true : m.loadingMural = false
        return m;
      }) 
      console.log("this.fastMural.Data", this.fastMural.Data)
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_mural/lista_ead?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_mural=" + id_mural,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        
        let obj = Array.from(json);
        if (obj.length) {
          this.fastMural.Data = this.fastMural.Data.map(m => {
            if (m.id_mural == id_mural) {
              m.html_publicacao = obj[0].html_publicacao
            }
            return m
          })
        }
        this.fastMural.Data = this.fastMural.Data.map(m => {
          m.loadingMural = false
          return m
        }) 
        console.log("this.fastMural.Data", this.fastMural.Data)

      } catch (e) {
        console.log("Erro", e);
      }
    },
    async getMuralPaginado() {
      
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_mural/lista_mural_paginado?PageIndex=" + this.fastMural.PageIndex + "&PageSize=" + this.fastMural.PageSize + "&IdPlataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();   
        this.fastMural.Loading = false     
        if (json.TotalResults) {
          this.fastMural.RangeStart = json.RangeStart
          this.fastMural.RangeEnd = json.RangeEnd
          this.fastMural.TotalResults = json.TotalResults          

          json.Data.forEach(m => { m.loadingMural = false; m.alteracao_pendente = false }) 
          this.fastMural.Data = this.fastMural.Data.concat(json.Data); 

          this.fastMural.PageIndex++  
        }       
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async criarMural() {
      let erros = [];
      if (!this.fastMuralNovo.titulo_publicacao)
        erros.push("O título é obrigatório");
      if (!this.fastMuralNovo.html_publicacao)
        erros.push("O nome texto é obrigatório");
      
      let email_envio = "N";
      if (this.fastMuralNovo.email_envio) email_envio = "S";

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.$store.state.fastCarregando = true;
        this.fastMuralNovo.id_mural = 0;
        this.fastMuralNovo.id_plataforma = this.$route.params.id_plataforma;
        this.fastMuralNovo.email_envio = email_envio;
        let obj = this.fastMuralNovo
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_mural/insere",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastMuralNovo))
          );
          
          let json = await resp.json();
          console.log(resp)
          let obj = Array.from(json);
          //this.getFastMural();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Publicação criada com sucesso",
          });
          this.fastMuralNovo = {
            data_alteracao: null,
            data_publicacao: null,
            html_publicacao: "",
            id_mural: 0,
            id_plataforma: 0,
            id_usuario_alteracao: 0,
            id_usuario_criacao: 0,
            logo_plataforma: "",
            nome_plataforma: "",
            tipo: "",
            titulo_publicacao: "",
            email_envio: false,
          };
          this.hideModal("modalCriarMural");
          this.$store.state.fastCarregando = false;
        } catch (e) {
          console.log("Erro", e);
          this.$store.state.fastCarregando = false;
        }
      }
    },
    async editarMural(mural) {
      this.fastMuralEditar = mural;
      let erros = [];
      if (!this.fastMuralEditar.titulo_publicacao)
        erros.push("O título é obrigatório");
      if (!this.fastMuralEditar.html_publicacao)
        erros.push("O nome texto é obrigatório");
      
      this.fastMuralEditar.tipo = this.fastMuralEditar.tipo.trim()

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastMuralEditar.id_plataforma = this.$route.params.id_plataforma;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_mural/atualiza",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastMuralEditar))
          );

          //let json = await resp.json();
          //let obj = Array.from(json);
          //this.getFastMural();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Publicação alterada com sucesso",
          });
          mural.alteracao_pendente = false;
        } catch (e) {
          console.log("Erro", e);
        }
      }
    },
    exibeModalExcluirMural(mural) {
      this.fastMuralExcluir = mural;
      this.showModal("modalExcluirMural");
    },
    exibeModalAdicionarImagem(mural) {
      this.fastMuralEditar = mural;
      console.log(this.fastMuralEditar)
      this.showModal("modalAdicionarImagem");
    },
    async excluirMural() {
      this.fastMuralExcluir.id_plataforma = this.$route.params.id_plataforma;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_mural/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(this.fastMuralExcluir))
        );

        let json = await resp.json();
        let obj = Array.from(json);
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Mural excluído com sucesso",
        });
        this.hideModal("modalExcluirMural");
        window.location.reload();
      } catch (e) {
        console.log("Erro", e);
      }
    },
    setImageMural(e) {
      this.exibeToasty("Carregando imagem...", "info");
      this.hideModal('modalAdicionarImagem');
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        
        this.exibeToasty("Selecione um arquivo de imagem", "error")
        return;
      }

      if (file.size / 1024 > 5120) {
        
        this.exibeToasty("O limite de tamanho do arquivo é 5MB", "error")
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "mural", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            console.log(retorno)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else { 
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email
                if(this.fastMuralNovo){             
                  this.fastMuralNovo.html_publicacao += `<img src="${retorno.url_image}" style="max-width: 100%">`
                  this.exibeToasty("Imagem inserida com sucesso", "success");
                } else{
                  console.log("aqui")
                  this.pageOfMural.html_publicacao += `<img src="${retorno.url_image}" style="max-width: 100%">`
                  this.exibeToasty("Imagem inserida com sucesso", "success");
                }
                
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        
        this.exibeToasty("Recurso não suportado pelo dispositivo", "error")
      }
    },
    setImageMuralEdit(e) {
      this.exibeToasty("Carregando imagem...", "info");
      this.hideModal('modalAdicionarImagem');
      console.log(e)
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        
        this.exibeToasty("Selecione um arquivo de imagem", "error")
        return;
      }

      if (file.size / 1024 > 5120) {
        
        this.exibeToasty("O limite de tamanho do arquivo é 5MB", "error")
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          // Captura imagem em base64
          let imageBase64 = event.target.result
          // Monta objeto
          const obj = {
            id_plataforma: this.$route.params.id_plataforma,
            tipo: "mural", // tipos: email, mural, questao
            imageBase64: imageBase64
          }
          // Envia imagem em base64 para server 
          this.promisePostFastApi(obj, "api/fast_plataforma/upload_image").then(res => {
            const retorno = JSON.parse(res)
            console.log(retorno)
            if (retorno.error) {
              this.exibeToasty(e.description, "error")
            } else { 
              if (retorno.url_image) {   
                // Captura link da imagem e insere no html do email
                  console.log(e)
                  this.fastMuralEditar.html_publicacao += `<img src="${retorno.url_image}" style="max-width: 100%">`
                  this.exibeToasty("Imagem inserida com sucesso", "success");
                
                
              }
            }
          }).catch(e => {         
            this.exibeToasty("Erro realizar upload da imagem", "error")
          })

          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        
        this.exibeToasty("Recurso não suportado pelo dispositivo", "error");
      }
    },
    actionScroll (event) {
      if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        console.log("Buscando murais...", this.fastMural.PageIndex, this.fastMural.PageSize)
        if (this.fastMural.TotalResults > this.fastMural.Data.length) this.getMuralPaginado()
        else 
          console.log('Total resultados foram exibidos')
      }
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.actionScroll);
  },
  created() {
    window.addEventListener('scroll', this.actionScroll);
  }
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/* Tabs */
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 1em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
</style>
